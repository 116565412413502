var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-form',{ref:"formBox",attrs:{"formBuilder":_vm.formBuilder},on:{"change":_vm.onchange}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options',
          { rules: [{ required: true, message: '请选择' }] },
        ]),expression:"[\n          'options',\n          { rules: [{ required: true, message: '请选择' }] },\n        ]"}],attrs:{"options":_vm.options,"placeholder":"请选择所属网格","change-on-select":"","show-search":{ filter: _vm.filter },"allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange}})],1),_c('div',{attrs:{"slot":"areaCode"},slot:"areaCode"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options1',
          {
            rules: [{ required: true, message: '请选择' }],
          },
        ]),expression:"[\n          'options1',\n          {\n            rules: [{ required: true, message: '请选择' }],\n          },\n        ]"}],attrs:{"show-search":{ filter: _vm.filter },"getPopupContainer":(triggerNode) => triggerNode.parentNode,"field-names":{
          label: 'label',
          value: 'label',
          children: 'children',
        },"options":_vm.options1,"placeholder":"请选择位置","allowClear":""},on:{"change":_vm.onChange1}})],1),_c('div',{attrs:{"slot":"fileIds"},slot:"fileIds"},[_c('imageUpload',{ref:"img",attrs:{"businessId":_vm.businessId}})],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.closeDialog}},[_vm._v(" 取消 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }