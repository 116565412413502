var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('ax-table',{ref:"roleTable",attrs:{"show-search":true,"searchForm":_vm.searchForm,"toolActions":_vm.toolActions,"columns":_vm.columns,"dataSourceApi":_vm.api.list,"dataSourceParams":_vm.dataSourceParams,"clickable":false,"showToolBar":true,"rowSelection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"searchActions":_vm.searchActions,"dynamicSlotNames":['gridId', 'areaCode'],"scroll":{ y: '45vh', x: '80vw' }},on:{"action-column-click":_vm.actionColumnClick,"bulkDeletion":_vm.deleteSecurityPersonnel,"add":_vm.add,"export":_vm.expor,"exportAll":_vm.exportAll,"reset":_vm.reset,"searchlist":_vm.searchlist,"pop-confirm":_vm.popConfirm}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{attrs:{"options":_vm.options,"change-on-select":"","show-search":{ filter: _vm.filter },"placeholder":"请选择所属网格","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666,"model":_vm.cuurentgridId},on:{"change":_vm.onChange},model:{value:(_vm.cuurentgridId1),callback:function ($$v) {_vm.cuurentgridId1=$$v},expression:"cuurentgridId1"}})],1),_c('div',{attrs:{"slot":"areaCode"},slot:"areaCode"},[_c('a-cascader',{attrs:{"show-search":{ filter: _vm.filter },"getPopupContainer":(triggerNode) => triggerNode.parentNode,"field-names":{
          label: 'label',
          value: 'label',
          children: 'children',
        },"options":_vm.options1,"placeholder":"请选择位置","z-index":666,"model":_vm.cuurentAreaCode},on:{"change":_vm.onChange1},model:{value:(_vm.cuurentAreaCode1),callback:function ($$v) {_vm.cuurentAreaCode1=$$v},expression:"cuurentAreaCode1"}})],1)]),_c('el-dialog',{attrs:{"title":"新增产业项目","visible":_vm.dialogAdd,"width":"60%","z-index":12,"destroy-on-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogAdd=$event}}},[_c('addPage',{on:{"closeDialog":function($event){_vm.dialogAdd = false},"refreshTable":_vm.refreshTable}})],1),_c('addedit',{ref:"edit",on:{"refreshTable":_vm.refreshTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }